div.milestoneEditor:not(.ag-cell-inline-editing) {
  /* border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px; */
  cursor: pointer;
  background: url("https://api.iconify.design/tabler/edit.svg?width=4&height=4")
    no-repeat right center / contain;
  background-size: 15px 15px;
  background-position-x: 95%;
}

div.milestoneSelectEditor:not(.ag-cell-inline-editing) {
  /* border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px; */
  cursor: pointer;
  background: url("https://api.iconify.design/gridicons/dropdown.svg") no-repeat
    right center / contain;
  background-size: 15px 15px;
  background-position-x: 90%;
  border-right-width: -2px;
}

div.ag-cell-inline-editing {
  height: calc(var(--ag-row-height)) !important;
}
div.milestoneSelectEditor.ag-cell-inline-editing {
  height: calc(var(--ag-row-height)) !important;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
}
div.milestoneEditor:hover {
  height: calc(var(--ag-row-height)) !important;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
}
div.milestoneSelectEditor:hover {
  height: calc(var(--ag-row-height)) !important;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
}
